import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from "gatsby"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import Layout2Columns from "../components/layout/Layout2Columns";
import SEO from "../components/seo"
import GoogleImage from "../components/GoogleImage"
import { devices, colors } from "../settings"
import HoverButton from "../components/buttons/HoverButton"

const Posts = styled.ul`
  list-style-type: none;
  padding: 0px;
  margin: 0px;
`
const Post = styled.li`
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 5px 5px #ccc;
  margin-bottom: 20px;
  display: flex;

  &:hover {
    border-color: #999;
    box-shadow: 0px 10px 10px #999;
  }

  @media ${devices.mobile} {
    display: block;
  }

  > div.preview {
    min-width: 250px;
    flex: 0;

    img {
      border-radius: 5px;
      width: 100%;
      height: auto;

      @media ${devices.mobile} {
        border-radius: 0px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }
    }
  }
  > div {
    flex: 1;
    padding: 10px;

    h2 {
      margin: 0px;
      padding: 0px;
      margin-bottom: 5px;
    }
    small {
      display: block;
      color: #999;
      margin-bottom: 10px;
    }
    p {
      margin-top: 0px;
    }
    
  }

`
const Categories = styled.div`
  h3 {
    text-align: center;
    background-color: #9c86ff;
    color: white;
    padding: 8px;
    margin: 0px;
    font-size: 120%;
    font-weight: normal;
  }
  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    li {
      padding: 5px;
      cursor: pointer;

      &.selected {
        background-color: #ccc !important;
      }

      &:hover {
        background-color: #eee;
      }
    }
  }
`

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    {
        posts: allMysqlBlogPosts(sort: {fields: added_date, order: DESC}) {
            edges {
                post: node {
                    id
                    name
                    title
                    added_date_formatted
                    preview_url
                    description,
                    category_ids                    
                }
            }
        }
        categories: allMysqlBlogCategories {
          edges {
              category: node {
                  id
                  category_id
                  name
                  title
                  posts
              }
          }
      }        
    }
  `);

  let formattedPosts = data.posts.edges.map(p => p.post);

  for (let post of formattedPosts) {
    post.categories = post.category_ids ? post.category_ids.split(",").map(c => parseInt(c)) : [];
  }

  //const [search, setSearch] = useState(null);
  const [posts, setPosts] = useState(formattedPosts)
  const [categoryId, setCategoryId] = useState(0);
  const [categories, setCategories] = useState(data.categories.edges)

  useEffect(
    () => {
      if (categoryId == 0)
        setPosts(formattedPosts);
      else
        setPosts(formattedPosts.filter(p => p.categories.indexOf(categoryId) != -1));
    }, 
    [categoryId]
); 

  return (
    <>
      <SEO page="blog" title="Blog" />
      <Page 
        breadcrumb={[
              { url: "/", title: "Home"},
              { url: null, title: "Blog"}
          ]}
      >
        <PageHeader title="SignMonkey Blog" />

        <Layout2Columns align="top" left={75} right={25}>
          <div>
            <Posts>
                {posts.map((post) => (
                  <Post key={post.id}>
                      <div className="preview">
                        <Link to={"/blog/" + post.name + "/"}>
                          <GoogleImage root required lazy src={post.preview_url} />
                        </Link>
                      </div>
                      <div>
                        <Link to={"/blog/" + post.name + "/"}>
                          <h2>{post.title}</h2>
                        </Link>
                        <small>{post.added_date_formatted}</small>
                        <p>{post.description}</p>

                        <Link to={"/blog/" + post.name + "/"}>
                          <HoverButton                       
                            label="Read More" 
                            size="small"
                            icon={faChevronRight}
                            iconPos="right"
                          />
                        </Link>
                      </div>
                  </Post>
                ))}
            </Posts>
          </div>
          <Categories>
            <h3>Categories</h3>
            <ul>
              {categories.map(({category}) => (
                <li onClick={() => setCategoryId(category.category_id)} className={categoryId == category.category_id ? "selected":""}>
                  {category.title} ({category.posts})
                </li>
              ))}
              <li onClick={() => setCategoryId(0)} className={categoryId == 0 ? "selected":""}>
                All Posts ({formattedPosts.length})
              </li>
            </ul>
          </Categories>
        </Layout2Columns> 
      </Page>
    </>
  )
}

export default BlogPage